<template>
  <contact-selection
    ref="select"
    :partner-id="partnerId"
    :center-id="centerId"
    :product-id="productId"
    v-bind="$attrs"
    :initial-value="initialValue"
    :name="name"
    :route="routeSearch"
    @request-creation="handleCreationRequest"
    @input="(e) => $emit('input', e)"
  >
    <sf-modal
      :id="computedModalId"
      :title="trans('client.new.title', {}, 'form')"
      :hide-footer="true"
    >
      <contact-creation
        :route="routeCreate"
        :partner-id="partnerId"
        @created="handleContactCreated"
      />
    </sf-modal>

    <template #helper>
      <slot name="helper" />
    </template>
  </contact-selection>
</template>

<script>
import { mapActions } from 'vuex';
import ContactCreation from './Create.vue';
import ContactSelection from './Select.vue';

export default {
  name: 'ContactSelectionOrCreation',
  components: { ContactCreation, ContactSelection },
  props: {
    partnerId: { type: Number, required: true },
    centerId: { type: Number, required: false, default: null },
    productId: { type: Number, required: false, default: null },
    initialValue: { type: Array, required: false, default: () => [] },
    name: { type: String, required: false, default: () => '' },
    modalId: { type: String, required: false, default: () => null },
    routeCreate: { type: String, required: true },
    routeSearch: { type: String, required: true },
  },
  emits: ['input'],

  computed: {
    computedModalId() {
      return this.$props.modalId || 'contact-creation-modal';
    },
  },

  methods: {
    ...mapActions('contact/select', ['add']),

    handleCreationRequest() {
      this.$bvModal.show(this.modalId);
    },

    handleContactCreated(contact) {
      this.add(contact.id)
        .then(() => {
          this.$refs.select.appendOption(
            contact.id,
            `${contact.firstName || ''} ${contact.lastName || ''}`
          );
        })
        .then(() => this.$bvModal.hide(this.modalId));
    },
  },
};
</script>
