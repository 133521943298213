<template>
  <b-form @submit.prevent="handleSubmit(item)">
    <b-row>
      <b-form-group
        id="input-group-firstName"
        class="col-6"
        label-for="input-firstName"
        :invalid-feedback="violations['customer.firstName']"
        :state="!isValid('customer.firstName') ? false : null"
      >
        <b-form-input
          id="input-firstName"
          v-model="item.customer.firstName"
          name="customer[firstName]"
          :state="!isValid('customer.firstName') ? false : null"
          :placeholder="trans('form.label.firstName')"
        />
      </b-form-group>

      <b-form-group
        id="input-group-lastName"
        class="col-6"
        label-for="input-lastName"
        :invalid-feedback="violations['customer.lastName']"
        :state="!isValid('customer.lastName') ? false : null"
      >
        <b-form-input
          id="input-lastName"
          v-model="item.customer.lastName"
          name="customer[lastName]"
          :placeholder="trans('form.label.lastName')"
          :state="!isValid('customer.lastName') ? false : null"
        />
      </b-form-group>
    </b-row>

    <b-form-group
      id="input-group-1"
      label-for="input-email"
      :description="trans('form.email.description')"
      :invalid-feedback="violations['customer.email']"
      :state="!isValid('customer.email') ? false : null"
    >
      <b-form-input
        id="input-email"
        v-model="item.customer.email"
        name="customer[email]"
        :state="!isValid('customer.email') ? false : null"
        type="email"
        placeholder="mail@example.org"
      />
    </b-form-group>

    <b-form-group
      id="input-group-1"
      :label="trans('form.birthdate')"
      label-for="input-birthdate"
      :invalid-feedback="violations['customer.birthdate']"
      :state="!isValid('customer.birthdate') ? false : null"
    >
      <date-type
        id="input-birthdate"
        v-model="item.customer.birthdate"
        show-decade-nav
        name="customer[birthdate]"
        :state="!isValid('customer.birthdate') ? false : null"
      />
    </b-form-group>

    <b-form-group :label="trans('form.label.phone.phone_number')">
      <b-row>
        <b-col cols="6">
          <mobile-cc-select2
            v-model="item.customer.mobile.country"
            name="customer[mobile[country]]"
            :class="['vue-select2', !isValid('customer.mobile.country') ? 'is-invalid' : '']"
          />
        </b-col>
        <b-col cols="6">
          <b-form-input
            id="mobile-number"
            v-model="item.customer.mobile.phoneNumber"
            name="customer[mobile[phone_number]]"
            :state="!isValid('customer.mobile.phone_number') ? false : null"
            :aria-label="trans('form.label.phone.phone_number')"
            placeholder="04xx xx xx xx"
          />
        </b-col>
        <!-- Required because of Bootstrap css @cf https://github.com/bootstrap-vue/bootstrap-vue/issues/1251 -->
        <b-col cols="12">
          <b-form-invalid-feedback
            v-if="violationsMobile"
            class="d-block"
          >
            {{ violationsMobile }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      :label="trans('voc.language')"
      :invalid-feedback="violations['customer.lang']"
    >
      <locale-select2
        v-model="item.customer.language"
        required="required"
        name="customer[language]"
        :class="['vue-select2', !isValid('customer.lang') ? 'is-invalid' : '']"
      />
    </b-form-group>

    <b-button
      type="submit"
      variant="success"
      :disabled="isLoading"
    >
      {{ isLoading ? trans('website.search.loading') : trans('label.submit', {}, 'form') }}
    </b-button>
  </b-form>
</template>

<script>
import DateType from '../../backoffice/Form/DateType.vue';
import LocaleSelect2 from '../../common/LocaleSelect2.vue';
import MobileCcSelect2 from '../../common/MobileCcSelect2.vue';

export default {
  name: 'ContactCreationForm',
  components: {
    DateType,
    MobileCcSelect2,
    LocaleSelect2,
  },
  props: {
    handleSubmit: {
      type: Function,
      required: true,
    },
    values: {
      type: Object,
      required: true,
    },
    initialValues: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    violations: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violationsMobile() {
      const violationsMobileCc = this.violations
        ? this.violations['customer.mobile.country'] || ''
        : '';
      const violationsMobileNumber = this.violations
        ? this.violations['customer.mobile.phone_number'] || ''
        : '';

      if (!violationsMobileNumber && !violationsMobileCc) {
        return null;
      }
      return `${violationsMobileCc}${violationsMobileCc && violationsMobileNumber ? '\n' : ''}${violationsMobileNumber}`;
    },
  },

  methods: {
    isSelected(collection, id) {
      return this.item[collection].find((i) => i['@id'] === id) !== undefined;
    },

    isValid(key) {
      return this.violations[key] === undefined;
    },
  },
};
</script>
