<template>
  <div>
    <slot name="loader" />
    <div
      v-if="created && created.result === 'OK'"
      class="alert alert-success"
    >
      {{ trans('flash.created', { type: 'contact' }) }}
    </div>
    <contact-creation-form
      :handle-submit="onSendForm"
      :values="item"
      :initial-values="item"
      :violations="violations"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import ContactCreationForm from './Form.vue';

const { mapFields } = createHelpers({
  getterType: 'contact/create/getField',
  mutationType: 'contact/create/updateField',
});

export default {
  name: 'ContactCreation',
  components: {
    ContactCreationForm,
  },
  props: {
    partnerId: { type: Number, required: true },
    centerId: { type: Number, required: false, default: undefined },
    route: { type: String, required: true },
  },
  emits: ['created'],
  data() {
    return {
      item: {
        customer: {
          email: '',
          mobile: {
            country: this.$store.state.center?.address?.country ?? '',
            phoneNumber: '',
          },
          firstName: '',
          lastName: '',
          language: 'fr',
        },
      },
    };
  },

  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations']),
  },

  async created() {
    if (!this.$store.state.center.address) {
      await this.$store.dispatch('center/FETCH_CENTER', this.$store.state.center.id);
    }
  },
  methods: {
    ...mapActions('contact/create', ['create']),

    reset() {
      this.item = {
        customer: {
          email: '',
          mobile: {
            country: this.$store.state.center.address?.country ?? '',
            phoneNumber: '',
          },
          firstName: '',
          lastName: '',
          language: 'fr',
        },
      };
    },

    onSendForm() {
      // Partner id is expected for contact creation
      const formattedData = {
        ...this.item,
        partner: { id: this.partnerId },
      };

      this.create({ route: this.$props.route, values: formattedData })
        // This might be moved to a watcher bound on vuex's 'created'
        .then((contact) => {
          if (!contact) {
            return false;
          }
          this.reset();
          return this.$emit('created', contact);
        });
    },
  },
};
</script>
