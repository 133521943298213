<template>
  <vue-select
    v-bind="$attrs"
    :settings="{
      theme: 'bootstrap',
    }"
    :options="options"
    @change="$emit('input', $event)"
  />
</template>

<script>
import Select2Component from '../mixins/Select2Component';

export default {
  name: 'LocaleSelect2',
  mixins: [Select2Component],
  emits: ['input'],
  computed: {
    contentMap: () => window.SportFinder.store.localesMap,
    preferredContentArray: () => window.SportFinder.store.preferredLocales,
    preferredContentMap: () => window.SportFinder.store.preferredLocalesMap,
  },
};
</script>
