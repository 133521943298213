<template>
  <b-input-group class="mb-3">
    <b-form-input
      :id="`${id}-input`"
      v-model="formattedDate"
      type="text"
      placeholder="YYYY-MM-DD"
      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
      autocomplete="off"
      oninput="this.reportValidity()"
    ></b-form-input>

    <b-input-group-append>
      <b-form-datepicker
        v-bind="$attrs"
        v-model="innerValue"
        button-only
        right
        :locale="spflocale"
        :aria-controls="`${id}-input`"
        start-weekday="1"
        :name="name"
        :initial-date="innerValue"
        reset-button
        value-as-date
        dropleft
        @context="onContext"
      ></b-form-datepicker>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { uuidv4 } from '../../../services/uuid';

const prefix0 = (v) => (v < 10 ? `0${v}` : v);

export default {
  props: {
    name: { type: String, required: true },
    value: { type: [Date, String], required: false, default: null },
  },
  emits: ['input'],
  data: () => ({
    id: uuidv4(),
    formatted: null,
  }),
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(v) {
        if (v instanceof Date) {
          this.$emit('input', v);
        }
      },
    },
    formattedDate: {
      get() {
        if (!this.innerValue) {
          return null;
        }

        return `${this.innerValue.getFullYear()}-${prefix0(this.innerValue.getMonth() + 1)}-${prefix0(this.innerValue.getDate())}`;
      },
      set(v) {
        const r = new RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}');
        if (r.test(v)) {
          //   console.log('formattedDate setter', v);
          //   console.log(new Date(v));
          this.$emit('input', new Date(v));
        }
      },
    },
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      //   this.formatted = ctx.selectedFormatted;
      //   this.formattedDate = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.innerValue = ctx.selectedYMD;
    },
  },
};
</script>
